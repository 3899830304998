import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="burger-menu"
export default class extends Controller {
  static targets = ["dropdown"];
  static values = { js: Boolean };

  connect() {
    this.jsValue = true;
  }

  toggle() {
    this.dropdownTarget.classList.toggle("nav--mobile-show");
  }

  close(e) {
    e.preventDefault();
    this.dropdownTarget.classList.remove("nav--mobile-show");
  }
}
