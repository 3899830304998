// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";

import "./controllers";
import "trix";
import "@rails/actiontext";

document.addEventListener("trix-before-initialize", () => {
  Trix.config.blockAttributes.heading1 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };
  Trix.config.blockAttributes.default.tagName = "p";
});

document.addEventListener("trix-initialize", (event) => {
  const { toolbarElement } = event.target;
  const input = toolbarElement.querySelector("input[name=href]");
  input.type = "text";
});
